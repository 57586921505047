import styles from "./MainScreen.module.css";
import { Title } from "./partial/title/Title";
import { ProjectPreview } from "../projects/partial/project-preview/ProjectPreview";
import { useMatchMedia } from "../../components/use-match-media/UseMatchMedia";

export const MainScreen = () => {
  const mobile = useMatchMedia("(max-width: 767px)", false);
  const tablet = useMatchMedia("(max-width: 1280px)", false);

  return (
    <>
      <Title />
      <div className={styles.uklon}>
        <ProjectPreview
          image={
            mobile
              ? "/images/uklon/Uklon_Project_Preview_Mobile.png"
              : tablet
              ? "/images/uklon/Uklon_Project_Preview_Tablet.png"
              : "/images/uklon/Uklon_Project_Preview.png"
          }
          title="Uklon Cargo"
          description="Development of the new service at Uklon — one of the biggest Ukrainian ride-hailing service."
          link="/projects/uklon"
          background="#FFE03B"
        />
      </div>
      <div className={styles.eventzone}>
        <ProjectPreview
          image={
            mobile
              ? "/images/eventzone/Event_Zone_Project_Preview_Mobile.png"
              : tablet
              ? "/images/eventzone/Event_Zone_Project_Preview_Tablet.png"
              : "/images/eventzone/Event_Zone_Project_Preview.png"
          }
          title="Eventzone"
          description="Mobile App for finding local events and things to do nearby, buying tickets and much more."
          link="/projects/eventzone"
          background="#BCCDFF"
        />
      </div>
    </>
  );
};
