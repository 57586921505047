import styles from "./UserResearch.module.css";
import { Section } from "../../../../../components/section/Section";

export const UserResearch = () => {
  return (
    <div className={styles.userResearchContainer}>
      <Section>
        <div className={styles.userResearch}>
          <h1 className="title">User research</h1>
          <span className="text">
            In order to find out which problems are faced by our target audience
            while moving , which steps they take to organise this process, we
            conducted a series of user inteviews. In total we received data from
            12 users.
          </span>
          <img
            className={styles.userInterviewsImage}
            src="/images/uklon/User_Interviews.png"
            alt="User Interviews"
          />
        </div>
      </Section>
    </div>
  );
};
