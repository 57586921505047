import React from "react";
import styles from "./App.module.css";
import { Layout } from "./components/layout/Layout";
import { Route, Routes, useLocation } from "react-router-dom";
import { MainScreen } from "./screens/main/MainScreen";
import { UklonScreen } from "./screens/projects/uklon/UklonScreen";
import { AboutScreen } from "./screens/about/AboutScreen";
import { ErrorScreen } from "./screens/error/ErrorScreen";
import { ProjectsScreen } from "./screens/projects/ProjectsScreen";
import { EventzoneScreen } from "./screens/projects/eventzone/EventzoneScreen";

export const App = () => {
  const location = useLocation();

  return (
    <div className="App">
      <div className={styles.screenTransition} />
      <Layout>
        <Routes location={location}>
          <Route path="/" element={<MainScreen />} />
          <Route path="/projects" element={<ProjectsScreen />} />
          <Route path="/projects/uklon" element={<UklonScreen />} />
          <Route path="/projects/eventzone" element={<EventzoneScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="*" element={<ErrorScreen />} />
        </Routes>
      </Layout>
    </div>
  );
};
