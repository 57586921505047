import styles from "./EventzoneDesigningPrototype.module.css";
import { Section } from "../../../../../components/section/Section";

export const EventzoneDesigningPrototype = () => {
  return (
    <Section>
      <div className={styles.container}>
        <h1 className="title">Designing prototype</h1>
        <span className="text">
          You won’t find any UX Researches here, as at the time I was working on
          this app I wasn’t familiar with this area yet. Learning UX was the
          next stage of my development as a UX/UI designer but I hope you’ll
          just enjoy the visual solutions and illustrations.
        </span>
        <div className={`${styles.section1} ${styles.section}`}>
          <div className={styles.section1Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Onboarding
            </h3>
            <span className={styles.sectionText}>
              These screens give a user a quick guide to the features and
              capabilities of the App. But given my recently gained knowledge of
              design principles, I would design onboarding differently and
              remove such a long onboarding, because it might lead to losing a
              large number of users at this stage. But, in any case, this
              hypothesis has to be checked.
            </span>
          </div>
          <div className={styles.section1Images}>
            <img
              className={styles.section1Image}
              src="/images/eventzone/Onboarding_01.png"
              alt="Onboarding 1"
            />
            <img
              className={styles.section1Image}
              src="/images/eventzone/Onboarding_02.png"
              alt="Onboarding 2"
            />
            <img
              className={styles.section1Image}
              src="/images/eventzone/Onboarding_03.png"
              alt="Onboarding 3"
            />
          </div>
        </div>

        <div className={`${styles.section2} ${styles.section}`}>
          <div className={styles.section2Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Login and Sign up screen
            </h3>
            <span className={styles.sectionText}>
              Here a user can log in with their email, Facebook, Google, or
              Apple — four options that are popular among typical users. When a
              field has been filled out correctly, here is also provided
              additional clarity by including a blue tick to indicate it. The
              user’s password is protected from onlookers by default, but they
              have the option to show their password if, for instance, they
              think a typo was made.
            </span>
          </div>
          <div className={styles.section2Images}>
            <img
              className={styles.section2Image}
              src="/images/eventzone/LogIn.png"
              alt="Log In"
            />
            <img
              className={styles.section2Image}
              src="/images/eventzone/Sign_Up_Default.png"
              alt="Sign Up Default"
            />
            <img
              className={styles.section2Image}
              src="/images/eventzone/Sign_Up_Active.png"
              alt="Sign Up Active"
            />
          </div>
        </div>

        <div className={`${styles.section3} ${styles.section}`}>
          <div className={styles.section3Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Registration process
            </h3>
            <span className={styles.sectionText}>
              Given my current knowledge of the effective design principles, I
              would change this registration process by leaving only the screen
              where the App prompts a user to select their interests. This
              screen is important because it makes user’s interaction with the
              App more efficient from the very beginning by suggesting events
              that match their interests. Now I understand that the screens,
              where a user is asked for the permissions to send notifications
              and track their location, are inappropriate here and a user is
              unlikely to understand the purpose of these promts at this stage.
              Therefore, it would be better to ask for permissions later when
              it’s really necessary. Because the login screen isn't why users
              come to our App - so it's better to let them to move through it as
              quickly as possible. The best way to ensure this is to keep things
              as simple as possible.
            </span>
          </div>
          <div className={styles.section3Images}>
            <img
              className={styles.section3Image}
              src="/images/eventzone/YourInterests.png"
              alt="Your Interests"
            />
            <img
              className={styles.section3Image}
              src="/images/eventzone/LocationPermission.png"
              alt="Location Permission"
            />
            <img
              className={styles.section3Image}
              src="/images/eventzone/LocationIOSPermission.png"
              alt="Location iOS Permission"
            />
            <img
              className={styles.section3Image}
              src="/images/eventzone/NotificationPermission.png"
              alt="Notification Permission"
            />
            <img
              className={styles.section3Image}
              src="/images/eventzone/NotificationIOSPermission.png"
              alt="Notification iOS Permission"
            />
            <img
              className={styles.section3Image}
              src="/images/eventzone/FinalScreen.png"
              alt="Final Screen"
            />
          </div>
        </div>

        <div className={styles.section4}>
          <div className={styles.section}>
            <div className={styles.section4Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Home screen for existing users
              </h3>
              <span className={styles.sectionText}>
                As for the Home screen, there are two different states of it.
                The first one is for existing users who, for example, already
                follow some organisers. Therefore, the App shows the events
                organised by these organisers in the first place and after that
                the recommended events that match with the users’ interests.
              </span>
            </div>
            <div className={styles.section4Images}>
              <img
                className={styles.section4Image}
                src="/images/eventzone/HomeScreenExisting.png"
                alt="Home Screen For Existing"
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section4Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Home screen for newcomers
              </h3>
              <span className={styles.sectionText}>
                Here is how the second state of the Home screen looks like. It
                is intended for users who have just started using the App. The
                main goal of this screen is to encourage a user to interact with
                the App. So the first thing shown here is the most popular
                organisers and events that may interest a user.
              </span>
            </div>
            <div className={styles.section4Images}>
              <img
                className={styles.section4Image}
                src="/images/eventzone/HomeScreenNew.png"
                alt="Home Screen For New"
              />
            </div>
          </div>
        </div>

        <div className={`${styles.section5} ${styles.section}`}>
          <div className={styles.section5Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Search screen
            </h3>
            <span className={styles.sectionText}>
              This screen includes many filters and adjustments to provide
              exactly those results which a user looking for. They work as
              multichoice to let user to choose, for instance, several different
              types of events if they need.
            </span>
          </div>
          <div className={styles.section5Images}>
            <img
              className={styles.section5Image}
              src="/images/eventzone/Search.png"
              alt="Search"
            />
            <img
              className={styles.section5Image}
              src="/images/eventzone/Filters.png"
              alt="Filters"
            />
          </div>
        </div>

        <div className={`${styles.section6} ${styles.section}`}>
          <div className={styles.section6Images}>
            <img
              className={styles.section6Image}
              src="/images/eventzone/Favourites.png"
              alt="Favourites"
            />
            <img
              className={styles.section6Image}
              src="/images/eventzone/Following.png"
              alt="Following"
            />
          </div>
          <div className={styles.section6Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Favourites
            </h3>
            <span className={styles.sectionText}>
              Whenever a user saves an event, it can be found in "Favourites".
              The events that are organised in the nearest future are above all
              others, no matter when they were saved. The organisers that a user
              follows are easily accessible in a separate tab and, in addition
              to that, it's possible to see how many events are available from a
              particular organiser without opening their profile.
            </span>
          </div>
        </div>

        <div className={styles.section7}>
          <div className={styles.section}>
            <div className={styles.section7Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Scheduled events
              </h3>
              <span className={styles.sectionText}>
                All events for which a user purchases tickets are displayed on
                this screen. Moreover, it has a separate tab for each month to
                organize content and not to overload a user with a full list of
                events in one screen.
              </span>
            </div>
            <div className={styles.section7Images}>
              <img
                className={styles.section7Image}
                src="/images/eventzone/YourEvents.png"
                alt="Your Events"
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section7Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                User profile
              </h3>
              <span className={styles.sectionText}>
                This screen contains a set of standard settings. “Profile”
                section pertains solely to the user, where they can change
                personal data or private information like payment methods.
                Purchased tickets can also be seen on this screen.
              </span>
            </div>
            <div className={styles.section7Images}>
              <img
                className={styles.section7Image}
                src="/images/eventzone/UserProfile.png"
                alt="User Profile"
              />
            </div>
          </div>
        </div>

        <div className={styles.section8}>
          <div className={styles.section}>
            <div className={styles.section8Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Organiser profile
              </h3>
              <span className={styles.sectionText}>
                On the organizer's page a user can read about a certain
                organizer, go to their social networks, see the details about
                their next performances or start following them. The organizer
                can be either a certain person or a museum, conference,
                exhibition center, etc.
              </span>
            </div>
            <div className={styles.section8Images}>
              <img
                className={styles.section8Image}
                src="/images/eventzone/OrganiserProfile.png"
                alt="Organiser Profile"
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section8Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Event screen
              </h3>
              <span className={styles.sectionText}>
                All the information about a certain festival are shown on this
                screen: date and venue, ticket price and description. Once a
                user decides they want to go to a certain event, the only thing
                they need to do is to pick the date and press “Buy ticket”.
              </span>
            </div>
            <div className={styles.section8Images}>
              <img
                className={styles.section8Image}
                src="/images/eventzone/EventPageDefault.png"
                alt="Event Page Default"
              />
            </div>
          </div>
        </div>

        <div className={`${styles.section9} ${styles.section}`}>
          <div className={styles.section9Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Ticket purchase
            </h3>
            <span className={styles.sectionText}>
              On this screen, a user can see total amount they need to pay and
              information about an event they want to buy ticket for. After the
              purchase is done, a user will be transmitted to another screen
              where they can check the order details one more time, download PDF
              or share the ticket with someone else.
            </span>
          </div>
          <div className={styles.section9Images}>
            <img
              className={styles.section9Image}
              src="/images/eventzone/EventPageChosen.png"
              alt="Event Page Chosen"
            />
            <img
              className={styles.section9Image}
              src="/images/eventzone/TicketAmount.png"
              alt="Ticket Amount"
            />
            <img
              className={styles.section9Image}
              src="/images/eventzone/Loader.png"
              alt="Loader"
            />
            <img
              className={styles.section9Image}
              src="/images/eventzone/TicketDetails.png"
              alt="Ticket Details"
            />
          </div>
        </div>

        <div className={`${styles.section10} ${styles.section}`}>
          <div className={styles.section10Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Empty states
            </h3>
            <span className={styles.sectionText}>
              I also designed empty states to communicate to a user in case
              there is no data to display or in case of any problems. This
              screens provide constructive guidance about user’s next steps.
            </span>
          </div>
          <div className={styles.section10Images}>
            <img
              className={styles.section10Image}
              src="/images/eventzone/EsNoConnection.png"
              alt="ES No Connection"
            />
            <img
              className={styles.section10Image}
              src="/images/eventzone/EsNoTickets.png"
              alt="ES No Tickets"
            />
            <img
              className={styles.section10Image}
              src="/images/eventzone/EsNoEvents.png"
              alt="ES No Events"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};
