import { ProjectBanner } from "./partial/project-banner/ProjectBanner";
import { ProjectOverview } from "./partial/project-overview/ProjectOverview";
import { UserResearch } from "./partial/user-research/UserResearch";
import { CompetitorAnalysis } from "./partial/competitor-analysis/CompetitorAnalysis";
import { CustomerProfile } from "./partial/customer-profile/CustomerProfile";
import { CustomerJourneyMap } from "./partial/customer-journey-map/CustomerJourneyMap";
import { FoundedProblems } from "./partial/founded-problems/FoundedProblems";
import { Ideation } from "./partial/ideation/Ideation";
import { DesigningPrototype } from "./partial/designing-prototype/DesigningPrototype";
import { Results } from "./partial/results/Results";

export const UklonScreen = () => {
  return (
    <div className="uklon-project">
      <ProjectBanner />
      <ProjectOverview />
      <UserResearch />
      <CompetitorAnalysis />
      <CustomerProfile />
      <CustomerJourneyMap />
      <FoundedProblems />
      <Ideation />
      <DesigningPrototype />
      <Results />
    </div>
  );
};
