import styles from "./CustomerProfile.module.css";
import { Section } from "../../../../../components/section/Section";

export const CustomerProfile = () => {
  return (
    <Section>
      <div className={styles.customerProfile}>
        <h1 className="title">Customer profile</h1>
        <span className="text">
          After conducting in-depth interviews, we had analysed the findings and
          created Customer Profile. We had defined Gains, Pains and Jobs to be
          done and grouped the issues by topics and categories.
        </span>
        <img
          className={styles.customerProfileImage}
          src="/images/uklon/Customer_Profile.jpg"
          alt="Customer Profile"
        />
      </div>
    </Section>
  );
};
