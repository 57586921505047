import styles from "./CompetitorAnalysis.module.css";
import { Section } from "../../../../../components/section/Section";

export const CompetitorAnalysis = () => {
  return (
    <Section>
      <div className={styles.competitorAnalysis}>
        <h1 className="title">Competitor analysis</h1>
        <span className="text">
          We also conducted research of 9 competitors to find out what works,
          avoid what doesn’t, and identify gaps to gain a competitive advantage.
        </span>
        <img
          className={styles.competitorAnalysisImage}
          src="/images/uklon/Competitor_Analysis.png"
          alt="Competitor Analysis"
        />
      </div>
    </Section>
  );
};
