import styles from "./Ideation.module.css";
import { Section } from "../../../../../components/section/Section";

export const Ideation = () => {
  return (
    <Section>
      <div className={styles.ideation}>
        <h1 className="title">Ideation. HWM</h1>
        <span className="text">
          Then we started brainstorming using “How Might We” technique to
          understand which features our service should have in order to make the
          process of moving as convenient as possible. As a result, we came up
          with more than 65 ideas.
        </span>
        <img
          className={styles.ideationImage}
          src="/images/uklon/Ideation.jpg"
          alt="Ideation"
        />
        <span className="text">
          After that, we voted and selected the most viable ideas, prioritized
          them by defining what needs to be done first and what should be holded
          for the next iterations.
        </span>
      </div>
    </Section>
  );
};
