import styles from "./ProjectsScreen.module.css";
import { Section } from "../../components/section/Section";
import { ProjectPreview } from "./partial/project-preview/ProjectPreview";
import { useMatchMedia } from "../../components/use-match-media/UseMatchMedia";

export const ProjectsScreen = () => {
  const mobile = useMatchMedia("(max-width: 767px)", false);
  const tablet = useMatchMedia("(max-width: 1280px)", false);

  return (
    <>
      <Section>
        <div className={styles.container}>
          <h1 className={`${styles.title} big-title`}>Projects</h1>
          <span className="text">
            Here you can see some of my latest work. Around here, design
            decisions aren’t made blindly. They’re foreseen with a clear
            understanding of how they guide users through delightful
            experiences, while returning successful outcomes to stakeholders.
          </span>
        </div>
      </Section>
      <Section>
        <div className={styles.year2022}>
          <span className="section-title-small">/ 2022</span>
        </div>
      </Section>
      <div className={styles.uklon}>
        <ProjectPreview
          image={
            mobile
              ? "/images/uklon/Uklon_Project_Preview_Mobile.png"
              : tablet
              ? "/images/uklon/Uklon_Project_Preview_Tablet.png"
              : "/images/uklon/Uklon_Project_Preview.png"
          }
          title="Uklon Cargo"
          description="Development of the new service at Uklon — one of the biggest Ukrainian ride-hailing service."
          link="uklon"
          background="#FFE03B"
        />
      </div>
      <div className={styles.eventzone}>
        <ProjectPreview
          image={
            mobile
              ? "/images/eventzone/Event_Zone_Project_Preview_Mobile.png"
              : tablet
              ? "/images/eventzone/Event_Zone_Project_Preview_Tablet.png"
              : "/images/eventzone/Event_Zone_Project_Preview.png"
          }
          title="Eventzone"
          description="Mobile App for finding local events and things to do nearby, buying tickets and much more."
          link="eventzone"
          background="#BCCDFF"
        />
      </div>
    </>
  );
};
