import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Section } from "../section/Section";
import { Hamburger } from "./partial/hamburger/Hamburger";
import { useAnimatedTransition } from "../transition/UseAnimatedTransition";

export const Header = () => {
  const [menuShown, setMenuShown] = useState(false);

  const location = useLocation();
  useEffect(() => setMenuShown(false), [location.pathname]);

  const navigate = useNavigate();
  const animatedTransition = useAnimatedTransition(navigate);

  return (
    <Section>
      <header className={`${styles.header} text`}>
        <nav>
          <NavLink
            to="/"
            className={styles.headerLogoLink}
            onClick={animatedTransition}
          >
            Mariia Lapitan-Yashchenko
          </NavLink>
        </nav>
        <nav className={styles.menuButton}>
          <Hamburger
            toggled={menuShown}
            onClick={() => setMenuShown(!menuShown)}
          />
        </nav>
        <nav
          className={
            styles.headerLinks +
            (menuShown ? " " + styles.headerLinksOnMobileOpened : "")
          }
        >
          <NavLink
            to="projects"
            className={({ isActive }) => {
              return (
                styles.headerLink +
                (isActive ? " " + styles.headerSelectedLink : "")
              );
            }}
            onClick={animatedTransition}
          >
            Projects
          </NavLink>
          <NavLink
            to="about"
            className={({ isActive }) => {
              return (
                styles.headerLink +
                (isActive ? " " + styles.headerSelectedLink : "")
              );
            }}
            onClick={animatedTransition}
          >
            About
          </NavLink>
        </nav>
      </header>
    </Section>
  );
};
