import styles from "./AboutMe.module.css";
import { Section } from "../../../../components/section/Section";
import { useMatchMedia } from "../../../../components/use-match-media/UseMatchMedia";

export const AboutMe = () => {
  const tablet = useMatchMedia("(max-width: 1280px)", false);
  const mobile = useMatchMedia("(max-width: 960px)", false);

  return (
    <Section>
      <div className={styles.aboutMeContainer}>
        <div className={styles.aboutMe}>
          <span className="big-title">About me</span>
          <span className="text">
            It was my conscious decision back in my childhood to connect my life
            with design and I’m still in love with it. I’ve been working as a
            graphic designer since 2017 and throughout my whole career I’ve
            always been looking for opportunities to learn and try something
            new. That's why I started studying UX/UI design. Now I focus on
            fine-tuning my skills in visual and interaction design and
            prototyping, in order to create and grow products that people will
            love. I adore working with grids, pictures and typography.
          </span>
          <p />
          <span className="text">
            Outside my work, you’ll find me reading different kinds of books —
            from psychology to political science, painting or paper-cutting ,
            cooking, exercising or travelling.
          </span>
        </div>
        <div className={styles.aboutMeImageContainer}>
          <img
            className={styles.aboutMeImage}
            src={tablet && !mobile ? "/images/Me_Tablet.jpg" : "/images/Me.jpg"}
            alt="Me"
          />
        </div>
      </div>
    </Section>
  );
};
