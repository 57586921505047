import styles from "./DesigningPrototype.module.css";
import { Section } from "../../../../../components/section/Section";
import { useMatchMedia } from "../../../../../components/use-match-media/UseMatchMedia";

export const DesigningPrototype = () => {
  const tablet = useMatchMedia("(max-width: 960px)", false);

  return (
    <Section>
      <div className={styles.container}>
        <h1 className="title">Designing prototype</h1>
        <span className="text">
          Then we applied all our knowledge for creating laconic and effective
          design solutions. At the same time, we adhered to the principles of
          the existing Uklon design system. The MVP scope had 20+ screens that
          covered principal functionality.
        </span>
        <div className={`${styles.section1} ${styles.section}`}>
          <div className={styles.section1Images}>
            <img
              className={styles.section1Image}
              src="/images/uklon/Integration_01.png"
              alt="Integration 1"
            />
            <img
              className={styles.section1Image}
              src="/images/uklon/Integration_02.png"
              alt="Integration 2"
            />
          </div>
          <div className={styles.section1Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Integration into the existing App
            </h3>
            <span className={styles.sectionText}>
              In the existing Uklon App there is a selection of popular services
              on the main screen. We decided to add our new service “Cargo” to
              the same list and mark it as a "New" to draw the users’ attention
              to the new service. As you can see on the second screen, we
              divided the “Cargo delivery” section into three types and used the
              existing Uklon App screen, just slightly modified it.
            </span>
          </div>
        </div>

        <div className={styles.section2}>
          <div className={styles.section}>
            <div className={styles.section2Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Large item shipping
              </h3>
              <span className={styles.sectionText}>
                If a user wants to deliver purchases from a hardware store, for
                example, we take into account that cargo might consist of
                appliances, furniture, building supplies and so on at the same
                time. That’s why we implemented a multi-choice when asking what
                exactly needs to be delivered.
              </span>
            </div>
            <div className={styles.section2Images}>
              <img
                className={styles.section2Image1}
                src="/images/uklon/Large_Items.png"
                alt="Large Items"
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section2Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Office moving
              </h3>
              <span className={styles.sectionText}>
                We have not worked out this flow at this stage as it requires a
                separate large-scale research so it’s not included in MVP.
                However, we added “Subscribe” button to see how popular this
                category is and whether we need to spend resources for
                extracting it in a dedicated screen.
              </span>
            </div>
            <div className={styles.section2Images}>
              <img
                className={styles.section2Image2}
                src="/images/uklon/Office_Moving.png"
                alt="Office Moving"
              />
            </div>
          </div>
        </div>

        <div className={`${styles.section3} ${styles.section}`}>
          <div className={styles.section3Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Apartment moving
            </h3>
            <span className={styles.sectionText}>
              This is the main flow on which we leveraged our effort. We divided
              the “Apartment moving” section into 3 types depending on the
              amount of things which need to be transported. When clicking on
              “Info” a user can see very detailed lists of items which are
              possible to transport using the selected type of moving. On the
              last step, we ask user to specify what kind of things they are
              going to transport. It is primarily useful for a driver to know
              and confirms whether the correct type was selected at the previous
              step.
            </span>
          </div>
          <div className={styles.section3Images}>
            <img
              className={styles.section3Image}
              src="/images/uklon/Apartment_Moving_01.png"
              alt="Apartment Moving 1"
            />
            <img
              className={styles.section3Image}
              src="/images/uklon/Apartment_Moving_02.png"
              alt="Apartment Moving 2"
            />
            <img
              className={styles.section3Image}
              src="/images/uklon/Apartment_Moving_03.png"
              alt="Apartment Moving 3"
            />
          </div>
        </div>

        <div className={styles.section4}>
          <div className={styles.section}>
            <div className={styles.section4Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Movers
              </h3>
              <span className={styles.sectionText}>
                Guided by the information specified by the user in the previous
                steps, we can determine how many loaders they need. We also
                determine how long it takes to load and unload their amount of
                things and inform user about it.
              </span>
            </div>
            <div className={styles.section4Images}>
              <img
                className={styles.section4Image1}
                src="/images/uklon/Movers.png"
                alt="Movers"
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section4Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Moving details
              </h3>
              <span className={styles.sectionText}>
                Here a user can select the number of passengers or specify
                another receiver. In case the user indicates that they want to
                accompany their cargo and go together with a driver, we also ask
                the user if they travel with a pet, as it might be important for
                the driver to know.
              </span>
            </div>
            <div className={styles.section4Images}>
              <img
                className={styles.section4Image2}
                src="/images/uklon/Moving_Details.png"
                alt="Moving Details"
              />
            </div>
          </div>
        </div>

        <div className={`${styles.section5} ${styles.section}`}>
          <div className={styles.section5Images}>
            <img
              className={styles.section5Image}
              src="/images/uklon/Order_Summary.png"
              alt="Order Summary"
            />
          </div>
          <div className={styles.section5Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Order summary
            </h3>
            <span className={styles.sectionText}>
              After we received all the necessary information, we let a user
              check the order again before proceeding, with the possibility to
              edit the data if necessary. At this step we also show the user a
              detailed cost calculation. If the user is satisfied with
              everything, he moves on to choosing the date and time.
            </span>
          </div>
        </div>

        <div className={styles.section6}>
          <div className={`${styles.section} ${styles.section6Subsection1}`}>
            <div className={styles.section6Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Scheduling
              </h3>
              <span className={styles.sectionText}>
                Some of our respondents mentioned that they had had difficulty
                to find someone who would fulfill their order, for example, in
                the evening. To avoid this problem when using our service, we
                show how many drivers and movers are available at a certain
                time.
              </span>
            </div>
            <div className={styles.section6Images}>
              <img
                className={styles.section6Image1}
                src="/images/uklon/Date_And_Time.png"
                alt="Date And Time"
              />
            </div>
          </div>
          <div className={`${styles.section} ${styles.section6Subsection2}`}>
            <div className={styles.section6Images}>
              <img
                className={styles.section6Image2}
                src={
                  tablet
                    ? "/images/uklon/Looking_For_Driver_Mobile.png"
                    : "/images/uklon/Looking_For_Driver.png"
                }
                alt="Looking For Driver"
              />
            </div>
            <div className={styles.section6Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Search for order executors
              </h3>
              <span className={styles.sectionText}>
                Here we used a native screen that already exists in Uklon App
                and is shown when searching a taxi driver.
              </span>
            </div>
          </div>
        </div>

        <div className={`${styles.section7} ${styles.section}`}>
          <div className={styles.section7Description}>
            <h3 className={`${styles.sectionTitle} section-title-small`}>
              Final screen
            </h3>
            <span className={styles.sectionText}>
              More than 50% of our respondents mentioned that it was hard for
              them to understand how they should package their belongings and
              where they can find all the necessary materials for this. Our
              solution was to add a banner which is shown after a user places an
              order. This banner leads to useful articles, where the user can
              find useful information related to packaging.
            </span>
          </div>
          <div className={styles.section7Images}>
            <img
              className={styles.section7Image}
              src="/images/uklon/Final_Screen.png"
              alt="Final Screen"
            />
          </div>
        </div>

        <div className={styles.section8}>
          <div className={`${styles.section} ${styles.section8Subsection1}`}>
            <div className={styles.section8Images}>
              <img
                className={styles.section8Image1}
                src={
                  tablet
                    ? "/images/uklon/Reminding_Mobile.png"
                    : "/images/uklon/Reminding.png"
                }
                alt="Reminding"
              />
            </div>
            <div className={styles.section8Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Reminding
              </h3>
              <span className={styles.sectionText}>
                If a user schedules their move in advance, they see a banner
                with a reminder on the main screen until the moving day comes.
              </span>
            </div>
          </div>
          <div className={`${styles.section} ${styles.section8Subsection2}`}>
            <div className={styles.section8Description}>
              <h3 className={`${styles.sectionTitle} section-title-small`}>
                Articles
              </h3>
              <span className={styles.sectionText}>
                Here you can see the screen with the articles I mentioned
                earlier. In addition to the useful information on how to pack
                certain things in the best way, a user will also find a list of
                places, where they can buy all the necessary packaging
                materials.
              </span>
            </div>
            <div className={styles.section8Images}>
              <img
                className={styles.section8Image2}
                src={
                  tablet
                    ? "/images/uklon/Articles_Mobile.png"
                    : "/images/uklon/Articles.png"
                }
                alt="Articles"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
