import styles from "./ProjectBanner.module.css";
import { Section } from "../../../../../components/section/Section";
import { ProjectDetailItem } from "../../../partial/project-detail-item/ProjectDetailItem";

export const ProjectBanner = () => {
  return (
    <div className={styles.bannerBackground}>
      <Section>
        <div className={styles.bannerContent}>
          <div className={styles.bannerDescription}>
            <span className="project-banner-title">Uklon Cargo</span>
            <div className={styles.projectDetailItems}>
              <ProjectDetailItem title="Timeline" description="October 2022" />
              <ProjectDetailItem
                title="Role"
                description="UX Research, Visual Design, Copywriting"
              />
              <ProjectDetailItem
                title="Tools"
                description="Figma, Adobe Illustrator"
              />
              <ProjectDetailItem
                title="Team"
                description="Me, Anastasia Krupelnytska, Anna Samohvalova, Olha Mitrova"
              />
            </div>
          </div>
          <div className={styles.projectBannerImageContainer}>
            <img
              className={styles.projectBannerImage}
              src="/images/uklon/Apartment_Moving.png"
              alt="Ap1"
            />
          </div>
        </div>
      </Section>
    </div>
  );
};
