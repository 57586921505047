import styles from "./EventzoneProjectOverview.module.css";
import { Section } from "../../../../../components/section/Section";

export const EventzoneProjectOverview = () => {
  return (
    <Section>
      <div className={styles.projectOverview}>
        <span className="title">Overview</span>
        <span className="text">
          This project is based on my own idea and it brought me my first
          hands-on experience in Mobile Design. Having researched similar Apps
          available on the Ukrainian market, I didn't find the one that provides
          all necessary features. So I decided to implement my own idea of the
          app is supposed to provide many opportunities — searching for events,
          purchasing tickets, following favourite organisers and much more.
        </span>
      </div>
    </Section>
  );
};
