import styles from "./AboutScreen.module.css";
import { AboutMe } from "./partial/about-me/AboutMe";
import { Experience } from "./partial/experience/Experience";

export const AboutScreen = () => {
  return (
    <div className={styles.container}>
      <AboutMe />
      <Experience />
    </div>
  );
};
