import styles from "./CustomerJourneyMap.module.css";
import { Section } from "../../../../../components/section/Section";

export const CustomerJourneyMap = () => {
  return (
    <Section>
      <div className={styles.customerJourneyMap}>
        <h1 className="title">CJM</h1>
        <span className="text">
          Then we filtered out similar problems and put the remaining insights
          into the СJM to understand which problems our user might face at
          certain steps when going through an order creation process.
        </span>
        <img
          className={styles.customerJourneyMapImage}
          src="/images/uklon/Customer_Journey_Map.jpg"
          alt="Customer Journey Map"
        />
      </div>
    </Section>
  );
};
