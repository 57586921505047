import styles from "./EventzoneProjectBanner.module.css";
import { Section } from "../../../../../components/section/Section";
import { ProjectDetailItem } from "../../../partial/project-detail-item/ProjectDetailItem";

export const EventzoneProjectBanner = () => {
  return (
    <div className={styles.bannerBackground}>
      <Section>
        <div className={styles.bannerContent}>
          <div className={styles.bannerDescription}>
            <span className="project-banner-title">Eventzone</span>
            <div className={styles.projectDetailItems}>
              <ProjectDetailItem title="Timeline" description="June 2022" />
              <ProjectDetailItem
                title="Role"
                description="Visual Design, Copywriting"
              />
              <ProjectDetailItem
                title="Tools"
                description="Figma, Adobe Illustrator"
              />
            </div>
          </div>
          <div className={styles.projectBannerImageContainer}>
            <img
              className={styles.projectBannerImage}
              src="/images/eventzone/EventzoneBanner.png"
              alt="Project Banner"
            />
          </div>
        </div>
      </Section>
    </div>
  );
};
