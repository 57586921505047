import styles from "./ProjectOverview.module.css";
import { Section } from "../../../../../components/section/Section";

export const ProjectOverview = () => {
  return (
    <Section>
      <div className={styles.projectOverview}>
        <span className="title">Overview</span>
        <div>
          <span className="text">
            Uklon — is one of the biggest Ukrainian ride-hailing service. It
            also offers carpooling, delivery, driver service, and other
            transportation services.
          </span>
          <span className="text">
            Cargo Taxi is a new service at Uklon that is currently in the
            process of development. We received a request from its stakeholders
            to develop this service and you will see the process of development
            here.
          </span>
        </div>
      </div>
    </Section>
  );
};
