import { MouseEventHandler } from "react";
import styles from "./Hamburger.module.css";

type HamburgerProps = {
  toggled?: boolean;
  onClick?: MouseEventHandler<any>;
};

export const Hamburger = (props: HamburgerProps) => {
  const toggled = props.toggled || false;

  return (
    <div
      className={styles.container}
      onClick={(event) => {
        if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div
        className={`${
          styles.line1 + (toggled ? " " + styles.toggledLine1 : "")
        }`}
      />
      <div
        className={`${
          styles.line2 + (toggled ? " " + styles.toggledLine2 : "")
        }`}
      />
    </div>
  );
};
