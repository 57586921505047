import styles from "./Results.module.css";
import { Section } from "../../../../../components/section/Section";

export const Results = () => {
  return (
    <Section>
      <div className={styles.results}>
        <h1 className="title">Results and takeaways</h1>
        <span className="text">
          After we finished designing our screens, we made an interactive
          prototype of the main flow and asked 5 different people to test it.
          The gathered feedback demonstrated that we did good work! The testers
          navigated through the low-interactive prototype very well. All of the
          tested people reached the end of the flow in a fairly short time -
          about 5 minutes.
        </span>
      </div>
    </Section>
  );
};
