import styles from "./Footer.module.css";
import { Section } from "../section/Section";
import { useState } from "react";

export const Footer = () => {
  const [copyToClipboard, setCopyToClipboard] = useState(false);

  return (
    <Section>
      <div className={styles.footer}>
        <div className={styles.footerSection}>
          <span className={`${styles.footerSectionTitle} text`}>
            Social media
          </span>
          <div className={styles.footerLinks}>
            <a
              className={`${styles.footerLink} text`}
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.facebook.com/marilapitan"
            >
              Facebook
            </a>
            <span className={`${styles.footerLinkDivider} text`}>/</span>
            <a
              className={`${styles.footerLink} text`}
              target={"_blank"}
              rel={"noreferrer"}
              href="https://instagram.com/mariia_lapitan?igshid=YmMyMTA2M2Y="
            >
              Instagram
            </a>
            <span className={`${styles.footerLinkDivider} text`}>/</span>
            <a
              className={`${styles.footerLink} text`}
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.behance.net/marilapitan"
            >
              Behance
            </a>
          </div>
        </div>
        <div className={styles.footerSection}>
          <span className={`${styles.footerSectionTitle} text`}>Email</span>
          <a
            className={`${styles.footerLink} text`}
            style={{ cursor: "pointer" }}
            onClick={async (event) => {
              event.preventDefault();
              await navigator.clipboard.writeText((event.target as any).text);

              setCopyToClipboard(true);
              setTimeout(() => setCopyToClipboard(false), 1500);
            }}
          >
            marilapitan@ukr.net
          </a>
          <span
            className={`${styles.copyToClipboard}${
              copyToClipboard ? " " + styles.success : ""
            } text`}
          >
            The email has been copied!
          </span>
        </div>
      </div>
    </Section>
  );
};
