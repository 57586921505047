import styles from "./ProjectDetailItem.module.css";

type ProjectDetailItemProps = {
  title: string;
  description: string;
};

export const ProjectDetailItem = ({
  title,
  description,
}: ProjectDetailItemProps) => {
  return (
    <div className={styles.container}>
      <span className={`text ${styles.title}`}>{title}</span>
      <span className="text">{description}</span>
    </div>
  );
};
