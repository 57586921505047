import styles from "./Experience.module.css";
import { Section } from "../../../../components/section/Section";
import { ExperienceItem } from "./partial/ExperienceItem";

export const Experience = () => {
  return (
    <>
      <Section>
        <div className={styles.experience}>
          <span className="section-title-small">/ Experience</span>
          <div className={styles.experienceList}>
            <hr />
            <ExperienceItem
              period={"Apr 2023 - current"}
              title={"Web designer, SendPulse"}
              description={
                "Improving existing UI elements and implementing new ones to enhance user interface. Creating Landing pages for events organised by the company. Designing web materials for marketing campaigns."
              }
            />
            <hr />
            <ExperienceItem
              period={"Sep 2018 - current"}
              title={
                "Freelance Graphic designer, Lika SUPPLY, LLC, United States"
              }
              description={
                "Creating advertising materials: labels, packages, booklets and other graphic materials. Photo retouching. Creating content for Amazon and Website. Designing store at Shopify."
              }
            />
            <hr />
            <ExperienceItem
              period={"Sep 2017 - Mar 2020"}
              title={"Graphic designer, TV Channel «ICTV»"}
              description={
                "Development of various design materials for printed and digital media, photo retouching, creating presentations. Promotion and development of corporate identity."
              }
            />
            <hr />
          </div>
        </div>
      </Section>
      <Section>
        <div className={styles.education}>
          <span className="section-title-small">/ Education</span>
          <div className={styles.educationList}>
            <hr />
            <ExperienceItem
              period={"Autumn 2022"}
              title={"Projector Institute. Mobile Interfaces Advanced"}
            />
            <hr />
            <ExperienceItem
              period={"Summer 2022"}
              title={"Projector Institute. Mobile Apps Design"}
            />
            <hr />
            <ExperienceItem
              period={"2015 - 2017"}
              title={
                "Graphic design, Bachelor. Borys Grinchenko Kyiv University, (with honours)"
              }
            />
            <hr />
            <ExperienceItem
              period={"2012 - 2015"}
              title={
                "Graphic design, Junior Specialist. Kyiv National University of Technologies and Design (College)"
              }
            />
            <hr />
          </div>
        </div>
      </Section>
    </>
  );
};
