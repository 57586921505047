import styles from "./FoundedProblems.module.css";
import { Section } from "../../../../../components/section/Section";

export const FoundedProblems = () => {
  return (
    <div className={styles.foundedProblemsContainer}>
      <Section>
        <div className={styles.foundedProblems}>
          <h1 className="title">Founded problems</h1>
          <span className="text">
            Based on the conducted research, we identified the following key
            issues that the respondents were facing when they were moving with
            the help of other Cargo-taxi services:
          </span>
          <div className={styles.foundedProblemsList}>
            <ul>
              <li>
                <h3>Car Selection</h3>
                <span>
                  It is difficult to understand which car is suitable for a
                  certain amount of things
                </span>
              </li>
              <li>
                <h3>Packaging</h3>
                <span>
                  Most apps or websites do not give any advice on how to pack
                  belongings
                </span>
              </li>
              <li>
                <h3>Insurance</h3>
                <span>
                  It’s not clear whether it is possible to receive a refund in
                  case the cargo is damaged
                </span>
              </li>
              <li>
                <h3>Scheduling</h3>
                <span>
                  It is often not possible to choose available slots for moving
                  right in the App
                </span>
              </li>
              <li>
                <h3>Pricing</h3>
                <span>
                  Mostly, approximate calculations are given, so there’s a risk
                  of paying a higher total price
                </span>
              </li>
              <li>
                <h3>Extra time</h3>
                <span>
                  It is not clear whether the provided minimum of time is enough
                  to load and unload staff
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Section>
    </div>
  );
};
