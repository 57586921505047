import styles from "../../App.module.css";
import { MouseEventHandler } from "react";
import gsap, { Power3 } from "gsap";
import { NavigateFunction } from "react-router-dom";

const timeline = gsap.timeline();

export const useAnimatedTransition: (
  navigate: NavigateFunction
) => MouseEventHandler<HTMLAnchorElement> = (navigate) => {
  return (event) => {
    event.preventDefault();

    const screenTransitionElement = "." + styles.screenTransition;

    if (timeline.isActive()) return;

    timeline
      .to(screenTransitionElement, {
        duration: 1,
        height: "100%",
        overflow: "hidden",
        ease: Power3.easeInOut,
        onComplete: () => navigate((event.target as any).pathname),
      })
      .to(screenTransitionElement, {
        duration: 1,
        bottom: "100%",
        ease: Power3.easeInOut,
        delay: 0.1,
        onComplete: () => {
          gsap.set(screenTransitionElement, { clearProps: "all" });
        },
      });
  };
};
