import styles from "./ExperienceItem.module.css";

type ExperienceItemProps = {
  period: string;
  title: string;
  description?: string;
};

export const ExperienceItem = ({
  period,
  title,
  description,
}: ExperienceItemProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.period}>{period}</span>
      <div className={styles.descriptionContainer}>
        <span className={styles.title}>{title}</span>
        {description ? (
          <span className={styles.description}>{description}</span>
        ) : undefined}
      </div>
    </div>
  );
};
