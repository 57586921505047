import { EventzoneProjectBanner } from "./partial/project-banner/EventzoneProjectBanner";
import { EventzoneProjectOverview } from "./partial/project-overview/EventzoneProjectOverview";
import { EventzoneDesigningPrototype } from "./partial/designing-prototype/EventzoneDesigningPrototype";
import { EventzoneResults } from "./partial/results/EventzoneResults";

export const EventzoneScreen = () => {
  return (
    <div className="eventzone-project">
      <EventzoneProjectBanner />
      <EventzoneProjectOverview />
      <EventzoneDesigningPrototype />
      <EventzoneResults />
    </div>
  );
};
