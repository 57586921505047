import styles from "./EventzoneResults.module.css";
import { Section } from "../../../../../components/section/Section";

export const EventzoneResults = () => {
  return (
    <Section>
      <div className={styles.results}>
        <h1 className="title">Thank you for your attention! </h1>
      </div>
    </Section>
  );
};
