import styles from "./Title.module.css";
import { Section } from "../../../../components/section/Section";

export const Title = () => {
  return (
    <Section>
      <div className={styles.container}>
        <span className="section-title-small">/ Hi, I'm Mariia</span>
        <span className="big-title">
          A designer focused on UX|UI and visual design, based in Kyiv, Ukraine.
        </span>
      </div>
    </Section>
  );
};
