import styles from "./ProjectPreview.module.css";
import { Section } from "../../../../components/section/Section";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useAnimatedTransition } from "../../../../components/transition/UseAnimatedTransition";

type ProjectPreviewProps = {
  image: string;
  title: string;
  description: string;
  link: string;

  background: string;
  className?: string;
};

export const ProjectPreview = (props: ProjectPreviewProps) => {
  const [hoverOn, setHoverOn] = useState(false);
  const additionalButtonStyle = hoverOn
    ? { backgroundColor: "#000000", color: props.background }
    : {};

  const navigate = useNavigate();
  const animatedTransition = useAnimatedTransition(navigate);

  return (
    <Section>
      <div
        className={styles.projectPreview}
        style={{ background: props.background }}
      >
        <img
          className={styles.projectPreviewImage}
          src={props.image}
          alt="Project Preview"
        />
        <div className={styles.projectPreviewDescription}>
          <span className="title">{props.title}</span>
          <span className="text">{props.description}</span>
          <NavLink
            className={`${styles.ellipseButton} text`}
            style={additionalButtonStyle}
            to={props.link}
            onMouseOver={() => setHoverOn(true)}
            onMouseOut={() => setHoverOn(false)}
            onClick={animatedTransition}
          >
            view case study
          </NavLink>
        </div>
      </div>
    </Section>
  );
};
